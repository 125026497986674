import React from "react";
import { Link } from "gatsby";
import GalleryComponent from "../components/gallery";
import { Container } from "reactstrap";

import Layout from "../components/layout";

const SecondPage = () => (
  <Layout title="Prop Maker - Gallery of Work">
    <Container>
      <h1>Gallery</h1>
      <GalleryComponent />
    </Container>
  </Layout>
);

export default SecondPage;
