import React from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  Row,
  Col,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container
} from "reactstrap";
import Lightbox from "react-image-lightbox";
import "./Lightbox.css";

import OriginalArt3D from "../images/gallery/compressed/art3d.jpeg";
import OriginalGiantTrophy from "../images/gallery/compressed/giantTrophy.jpg";
import OriginalGiantTube from "../images/gallery/compressed/giantTube.jpg";
import OriginalGiantBottle from "../images/gallery/compressed/giantBottle.jpg";
import OriginalGPS from "../images/gallery/compressed/gps.png";
import OriginalIceDragon from "../images/gallery/compressed/iceDragon.jpg";
import OriginalLung from "../images/gallery/compressed/lung.jpg";
import OriginalShowroomBottles from "../images/gallery/compressed/showroomBottles.jpg";

import ThumbnailArt3D from "../images/gallery/thumbnails/art3d.jpeg";
import ThumbnailGiantTrophy from "../images/gallery/thumbnails/giantTrophy.jpg";
import ThumbnailGiantTube from "../images/gallery/thumbnails/giantTube.jpg";
import ThumbnailGiantBottle from "../images/gallery/thumbnails/giantBottle.jpg";
import ThumbnailGPS from "../images/gallery/thumbnails/gps.png";
import ThumbnailIceDragon from "../images/gallery/thumbnails/iceDragon.jpg";
import ThumbnailLung from "../images/gallery/thumbnails/lung.jpg";
import ThumbnailShowroomBottles from "../images/gallery/thumbnails/showroomBottles.jpg";

class GalleryComponent extends React.Component {
  state = {
    photoIndex: 0,
    isOpen: false,
    images: [
      {
        thumbnail: ThumbnailArt3D,
        original: OriginalArt3D,
        title: "3D Art"
      },
      {
        thumbnail: ThumbnailGiantTrophy,
        original: OriginalGiantTrophy,
        title: "Giant Trophy at the MGM"
      },
      {
        thumbnail: ThumbnailGiantTube,
        original: OriginalGiantTube,
        title: "Giant tube"
      },
      {
        thumbnail: ThumbnailGiantBottle,
        original: OriginalGiantBottle,
        title: "Giant Bottle"
      },
      {
        thumbnail: ThumbnailGPS,
        original: OriginalGPS,
        title: "Giant GPS unit"
      },
      {
        thumbnail: ThumbnailIceDragon,
        original: OriginalIceDragon,
        title: "Ice dragon head"
      },
      {
        thumbnail: ThumbnailLung,
        original: OriginalLung,
        title: "Lung display"
      },
      {
        thumbnail: ThumbnailShowroomBottles,
        original: OriginalShowroomBottles,
        title: "Showroom bottles"
      }
    ]
  };

  renderImages = () => {
    const { images } = this.state;

    return images.map((image, i) => {
      const privateKey = i;
      return (
        <Col lg="4" md="4" sm="6" key={i}>
          <figure>
            <img
              src={image.thumbnail}
              alt="Gallery"
              className="img-fluid"
              onClick={() =>
                this.setState({ photoIndex: privateKey, isOpen: true })
              }
            />
          </figure>
        </Col>
      );
    });
  };

  render() {
    const { photoIndex, isOpen, images } = this.state;
    return (
      <Container className="mt-5">
        <div className="mdb-lightbox">
          <Row>{this.renderImages()}</Row>
        </div>
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex].original}
            nextSrc={images[(photoIndex + 1) % images.length].original}
            prevSrc={
              images[(photoIndex + images.length - 1) % images.length].original
            }
            imageTitle={images[photoIndex].title}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length
              })
            }
          />
        )}
      </Container>
    );
  }
}

export default GalleryComponent;
